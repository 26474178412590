import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";

class BlogPostTemplate extends React.Component {
  render() {
    const { post } = this.props.data;

    return (
      <Layout
        location={this.props.location}
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      >
        <header>
          <h1>{post.frontmatter.title}</h1>
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;
